.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
    overflow: hidden;
}

* :focus:not(:focus-visible) {
    outline: none;
}

* :focus-visible {
    border-radius: var(--border-radius);
    outline: 0.15rem solid var(--color-magenta);
    outline-offset: 0.15rem;
    box-shadow: var(--box-shadow-size) var(--color-cyan);
}

:-webkit-autofill {
    box-shadow: var(--box-shadow-autofill) var(--color-black) inset;

    &:focus {
        box-shadow: var(--box-shadow-size) var(--color-cyan),
            var(--box-shadow-autofill) var(--color-background) inset;
    }
}

::selection {
    color: var(--color-black);
    background: var(--color-cyan);
}
