/* cyrillic */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/48a731e2528b0fa7.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/551b9141924f6103.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/f85abf8f572b7d3b.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/48a731e2528b0fa7.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/551b9141924f6103.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/f85abf8f572b7d3b.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/48a731e2528b0fa7.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/551b9141924f6103.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f85abf8f572b7d3b.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/48a731e2528b0fa7.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/551b9141924f6103.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Jost_188543';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f85abf8f572b7d3b.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Jost_Fallback_188543';src: local("Arial");ascent-override: 112.02%;descent-override: 39.26%;line-gap-override: 0.00%;size-adjust: 95.51%
}.__className_188543 {font-family: '__Jost_188543', '__Jost_Fallback_188543';font-style: normal
}.__variable_188543 {--font-sans: '__Jost_188543', '__Jost_Fallback_188543'
}

/* latin-ext */
@font-face {
  font-family: '__Courier_Prime_e7cc6c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/39ff50bc98750743.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Courier_Prime_e7cc6c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/dc21cde2783fcd95.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Courier_Prime_e7cc6c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d84e3c9a6e9693a9.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Courier_Prime_e7cc6c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/616a24cbf71ceb7f.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Courier_Prime_Fallback_e7cc6c';src: local("Arial");ascent-override: 59.45%;descent-override: 26.01%;line-gap-override: 0.00%;size-adjust: 131.41%
}.__className_e7cc6c {font-family: '__Courier_Prime_e7cc6c', '__Courier_Prime_Fallback_e7cc6c';font-style: normal
}.__variable_e7cc6c {--font-mono: '__Courier_Prime_e7cc6c', '__Courier_Prime_Fallback_e7cc6c'
}

.Layout_wrapper__XgCoX,
.Layout_main__gQx8r {
    position: relative;
    min-height: 100%;
    height: 100%;
}

.Error_alert__uLDiW {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.Error_title__cyi6q {
    margin: 0 0 3rem;
    font-size: 3rem;
    font-weight: var(--font-extra-light);
}

.Error_btn__opJNr {
    padding: var(--spacing);
    color: var(--color-text);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.1rem;
    border: 1px solid var(--color-text);
    -webkit-backdrop-filter: blur(0.1em) brightness(var(--backdrop-brightness));
            backdrop-filter: blur(0.1em) brightness(var(--backdrop-brightness));
    transition: all 0.15s ease-in-out;
}

.Error_btn__opJNr:focus,
.Error_btn__opJNr:hover {
    color: var(--color-background);
    background-color: var(--color-text);
    border-radius: var(--border-radius);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
}

:root {
    --max-width: 800px;
    --color-background: #131313;
    --color-text: #fbffff;
    --color-black: #131313;
    --color-white: #fbffff;
    --color-cyan: #00fff0;
    --color-magenta: #eb0042;
    --box-shadow-size: 0px 0px 10px 10px;
    --box-shadow-autofill: 0 0 0px 100px;
    --border-radius: 0.15rem;
    --backdrop-brightness: 60%;
    --scroll-behaviour: smooth;
    --spacing: 1rem;
    --font-extra-light: 100;
    --font-light: 200;
    --font-normal: 400;
}

@media screen and (prefers-contrast: more) {
    :root {
        --backdrop-brightness: 40%;
        --font-extra-light: 400;
        --font-light: 400;
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    :root {
        --scroll-behaviour: auto;
    }
}

@media print {
    :root {
        --color-background: #ffffff;
        --color-text: #000000;
    }
}

html {
    scroll-behavior: var(--scroll-behaviour);
}

html,
body,
#__next {
    min-height: 100% !important;
    height: 100%;
}

html,
body {
    background-color: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-sans);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
    white-space: nowrap;
    overflow: hidden;
}

* :focus:not(:focus-visible) {
    outline: none;
}

* :focus-visible {
    border-radius: var(--border-radius);
    outline: 0.15rem solid var(--color-magenta);
    outline-offset: 0.15rem;
    box-shadow: var(--box-shadow-size) var(--color-cyan);
}

:-webkit-autofill {
    box-shadow: var(--box-shadow-autofill) var(--color-black) inset;

    &:focus {
        box-shadow: var(--box-shadow-size) var(--color-cyan),
            var(--box-shadow-autofill) var(--color-background) inset;
    }
}

::selection {
    color: var(--color-black);
    background: var(--color-cyan);
}

