.alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title {
    margin: 0 0 3rem;
    font-size: 3rem;
    font-weight: var(--font-extra-light);
}

.btn {
    padding: var(--spacing);
    color: var(--color-text);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.1rem;
    border: 1px solid var(--color-text);
    backdrop-filter: blur(0.1em) brightness(var(--backdrop-brightness));
    transition: all 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
    color: var(--color-background);
    background-color: var(--color-text);
    border-radius: var(--border-radius);
}
